import React from "react";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import LabAccordion from "../ui/LabAccordion";
import { CLIENT_URL } from "../../lib/urls";

const navItems = [
  {
    title: "Help",
    links: [
      { name: "Contact Us", href: `${CLIENT_URL}/contact-us` },
      { name: "Help Center", href: `${CLIENT_URL}/help` },
    ],
  },
  {
    title: "About Us",
    links: [
      { name: "About Us", href: `${CLIENT_URL}/about-us` },
      /*  { name: "weareprintlab Blog", href: "#" }, */
      { name: "Careers", href: `${CLIENT_URL}/careers` },
    ],
  },
  {
    title: "FAQ",
    links: [
      { name: "FAQ", href: `${CLIENT_URL}/faq` },
      /*  { name: "Sample Packs", href: "#" },
      { name: "Tradeprint API", href: "#" },
      { name: "Tradeprint PRO", href: "#" }, */
    ],
  },
  {
    title: "Important",
    links: [
      { name: "Terms & Conditions", href: `${CLIENT_URL}/terms` },
      { name: "Privacy & Cookie Policy", href: `${CLIENT_URL}/privacy` },
      {
        name: "Environmental Policy",
        href: `${CLIENT_URL}/environmental-policy`,
      },
    ],
  },
];

const ClientFooter = () => {
  return (
    <>
      <section className="container py-5 mx-auto text-white">
        <div className="flex flex-col items-center justify-between max-w-5xl gap-3 py-5 mx-auto md:flex-row text-primary-text ">
          <div className="flex-1">
            <p className="text-sm">
              Sign me up for exclusive offers and print inspiration by email
            </p>
          </div>

          <div className="flex-1">
            <div>
              <label htmlFor="hs-trailing-button-add-on" class="sr-only">
                Label
              </label>
              <div class="flex rounded-sm flex-wrap gap-2 md:gap-0 justify-center ">
                <input
                  type="text"
                  id="hs-trailing-button-add-on"
                  name="hs-trailing-button-add-on"
                  class="rounded-none ti-form-input text-secondgraphy px-2 rounded-s-sm focus:z-10"
                />
                <button
                  type="button"
                  class="inline-flex items-center justify-center flex-shrink-0 gap-2 px-3 py-2 text-sm font-semibold  transition-all border border-transparent rounded-e-sm bg-primary hover:bg-primary focus:z-10 focus:outline-none focus:ring-0 focus:ring-primary text-secondgraphy"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr className="w-full text-typography" />
        <div className="flex flex-col gap-4 py-5 md:flex-row">
          <div className="flex flex-col items-start justify-between flex-1 w-full gap-5 md:flex-row md:gap-10">
            {navItems.map((navItem, index) => (
              <div key={index} className="w-full">
                <div className="hidden md:block">
                  <h4 className="text-lg font-semibold ">{navItem.title}</h4>
                  <ul className="space-y-2">
                    {navItem.links.map((link, index) => (
                      <li key={index}>
                        <a
                          href={link.href}
                          className="text-sm hover:text-primary"
                        >
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="block md:hidden">
                  <LabAccordion title={navItem.title}>
                    <ul className="space-y-2">
                      {navItem.links.map((link, index) => (
                        <li key={index}>
                          <a
                            href={link.href}
                            className="text-sm hover:text-primary"
                          >
                            {link.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </LabAccordion>
                </div>
              </div>
            ))}
          </div>

          <div className="block md:hidden">
            <LabAccordion title="Social Links" triggerStyle="bg-primary">
              <div>
                <h4 className="text-lg font-semibold text-white">Follow Us</h4>
                <div className="flex items-center w-full max-w-xl gap-2">
                  <a
                    href="/"
                    target="_blank"
                    className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
                  >
                    <FaLinkedinIn />
                  </a>{" "}
                  <a
                    href="https://www.facebook.com/ukprintlab"
                    rel="noreferrer"
                    target="_blank"
                    className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
                  >
                    <FaFacebookF />
                  </a>{" "}
                  <a
                    href="https://www.instagram.com/printlab_uk/"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
                  >
                    <FaInstagram />
                  </a>{" "}
                  <a
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
                  >
                    <FaYoutube />
                  </a>{" "}
                  <a
                    href="/"
                    target="_blank"
                    className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
                  >
                    <FaXTwitter />
                  </a>{" "}
                </div>
              </div>
            </LabAccordion>
          </div>
          <div className="hidden md:block">
            <h4 className="mb-2 text-lg font-semibold text-white">Follow Us</h4>
            <div className="flex items-center w-full max-w-xl gap-2">
              <a
                href="/"
                target="_blank"
                className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
              >
                <FaLinkedinIn />
              </a>{" "}
              <a
                href="https://www.facebook.com/ukprintlab"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
              >
                <FaFacebookF />
              </a>{" "}
              <a
                href="https://www.instagram.com/printlab_uk/"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
              >
                <FaInstagram />
              </a>{" "}
              <a
                href="/"
                target="_blank"
                className="flex items-center justify-center rounded-full bg-primary w-7 h-7 text-secondgraphy"
              >
                <FaYoutube />
              </a>{" "}
            </div>
          </div>
        </div>
      </section>
      <section className="text-white bg-typography">
        <div className="container py-5 mx-auto ">
          <div className="flex items-center justify-between w-full py-5 text-sm sm:text-base ">
            <p>
              2024 © weareprintlab. All Rights Reserved. weareprintlab is a
              trading
            </p>
            <a
              href={`${CLIENT_URL}`}
              className="hidden text-white underline hover:text-primary md:block"
            >
              Home
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientFooter;
